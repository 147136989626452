import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {jwtDecode} from "jwt-decode";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Para obtener el token desde la URL
  const [tokenExpired, setTokenExpired] = useState(false); // Estado para manejar si el token expiró

  // Obtener el token de la URL
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  // Verificar si el token ha expirado
  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        // Verificar si el token ha expirado
        if (decodedToken.exp < currentTime) {
          setTokenExpired(true);
          Swal.fire({
            icon: "error",
            title: "El enlace ha expirado",
            text: "El enlase de recuperación de contraseña ha caducado. Por favor solicita uno nuevo.",
            confirmButtonText: "Ok",
          }).then(() => {
            navigate("/login"); // Redirige al login después de mostrar el mensaje
          });
        }
      } catch (error) {
        console.error("Error al decodificar el token:", error);
        setTokenExpired(true);
      }
    }
  }, [token, navigate]);

  // Función para manejar el envío del formulario de cambio de contraseña
  const onFinish = async (values) => {
    const { newPassword, confirmPassword } = values;

    // Validar que las contraseñas coincidan
    if (newPassword !== confirmPassword) {
      return message.error("Las contraseñas no coinciden");
    }

    setLoading(true);

    try {
      // Solo enviar la solicitud si el token no ha expirado
      if (!tokenExpired) {
        // Enviar la solicitud de cambio de contraseña al backend
        const response = await axios.post(`${process.env.REACT_APP_backend}/reset-password`, {
          token,
          newPassword,
        });

        // Mostrar mensaje de éxito y redirigir a la página de login
        message.success(response.data.mensaje);
        navigate("/login");
      } else {
        Swal.fire({
          icon: "error",
          title: "El token ha expirado",
          text: "Por favor solicita un nuevo enlace de restablecimiento de contraseña.",
        });
      }
    } catch (error) {
      // Manejo de errores
      console.error("Error al restablecer la contraseña:", error);
      if (error.response) {
        message.error(error.response.data.mensaje || "Error al restablecer la contraseña");
      } else {
        message.error("Error de red");
      }
    } finally {
      setLoading(false);
    }
  };

  // Función para redirigir al login cuando se presiona el botón "Volver"
  const handleBackToLogin = () => {
    navigate("/login");
  };

  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Col xs={24} sm={12} md={8}>
        <h2 style={{ textAlign: "center", marginBottom: "30px" }}>Restablecer Contraseña</h2>

        <Form
          name="reset-password"
          layout="vertical"
          onFinish={onFinish}
          style={{ maxWidth: "400px", margin: "0 auto" }}
        >
          <Form.Item
            label="Nueva Contraseña"
            name="newPassword"
            rules={[{ required: true, message: "Por favor ingrese la nueva contraseña" }]}
          >
            <Input.Password placeholder="Ingrese su nueva contraseña" />
          </Form.Item>

          <Form.Item
            label="Confirmar Contraseña"
            name="confirmPassword"
            dependencies={['newPassword']}
            hasFeedback
            rules={[
              { required: true, message: "Por favor confirme su contraseña" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Las contraseñas no coinciden"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirme su nueva contraseña" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: "100%" }} disabled={tokenExpired}>
              {loading ? "Restableciendo..." : "Restablecer Contraseña"}
            </Button>
          </Form.Item>

          {/* Botón para volver al login */}
          <Form.Item>
            <Button type="default" onClick={handleBackToLogin} style={{ width: "100%" }}>
              Volver
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ResetPassword;
