import React, { useState, useEffect, useCallback } from "react";
import { List, Input, Button, Row, Col, Typography, Form, Modal, Table, Dropdown, Menu } from "antd";
import { PlusOutlined, EyeOutlined, EditOutlined, KeyOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
import './Admin.css';

const { Title } = Typography;

const Admin = ({ rol, userRole, userId }) => {
  const pageSize = 5;
  const [searchText, setSearchText] = useState("");
  const [usuarios, guardarUsuarios] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editField, setEditField] = useState(null); // Para identificar el campo que se está editando
  const [editValue, setEditValue] = useState(""); // Para capturar el nuevo valor editado
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState("Usuarios"); // Para manejar el valor seleccionado
  const [form] = Form.useForm(); // Form instance for password validation

  const primaryColor = "#007BFF";
  const dangerColor = "#FF4C4C";

  useEffect(() => {
    if (userId) {
      consultarPerfil();
    } else {
      setSelectedUser(null);
    }
  }, [rol, userId]);

  const consultarPerfil = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_backend}/usuarios/${userId}`);
      guardarUsuarios([response.data]);
      setSelectedUser(response.data);
    } catch (error) {
      console.error("Error al consultar el perfil", error);
    }
  };

  const consultarAPI = useCallback(async () => {
    try {
      const ruta = userId
        ? `${process.env.REACT_APP_backend}/usuarios/${userId}`
        : `${process.env.REACT_APP_backend}/usuarios?rol=${rol || ''}`;
      const usuariosConsulta = await axios.get(ruta);

      if (userId) {
        guardarUsuarios([usuariosConsulta.data]);
      } else {
        guardarUsuarios(usuariosConsulta.data);
      }
    } catch (error) {
      console.error("Error al consultar la API", error);
    }
  }, [rol, userId]);

  useEffect(() => {
    if (!userId) consultarAPI();
  }, [consultarAPI, userId]);

  const hidePasswordModal = () => {
    setPasswordModalVisible(false);
    form.resetFields(); // Limpiar los campos del formulario al cerrar el modal
  };

  const hideNewUserModal = () => {
    setModalVisible(false);
    form.resetFields(); // Limpiar los campos del formulario de nuevo usuario al cerrar
  };

  const actualizarUsuario = async (field, value) => {
    if (value === "") return;

    try {
      await axios.put(
        `${process.env.REACT_APP_backend}/usuarios/${selectedUser._id}`,
        { [field]: value }
      );
      consultarAPI();
      setEditField(null); // Salir del modo de edición
      setSelectedUser((prev) => ({ ...prev, [field]: value }));
      Swal.fire({
        icon: "success",
        title: "Actualización exitosa",
        text: "El usuario ha sido actualizado correctamente",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.mensaje || "Error al actualizar el usuario.",
          confirmButtonText: "Ok",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error al actualizar el usuario.",
          confirmButtonText: "Ok",
        });
      }
    }
  };

  const cambiarPassword = async (values) => {
    const { oldPassword, password } = values;

    const payload = userId === selectedUser._id
      ? { oldPassword, newPassword: password }  // Incluye contraseña antigua solo para "Mi Perfil"
      : { newPassword: password };              // No incluye contraseña antigua para otros usuarios

    try {
      await axios.put(`${process.env.REACT_APP_backend}/usuarios/${selectedUser._id}`, payload);
      hidePasswordModal();
      Swal.fire({
        icon: "success",
        title: "Contraseña actualizada",
        text: "La contraseña ha sido cambiada correctamente.",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.mensaje || "Error al actualizar la contraseña.",
        confirmButtonText: "Ok",
      });
    }
  };

  const crearNuevoUsuario = async (values) => {
    try {
      await axios.post(`${process.env.REACT_APP_backend}/usuarios`, values);
      hideNewUserModal();
      Swal.fire({
        icon: "success",
        title: "Usuario creado",
        text: "El usuario ha sido creado correctamente.",
        confirmButtonText: "Ok",
      });
      consultarAPI(); // Recargar la lista de usuarios
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.mensaje || "Error al crear el usuario.",
        confirmButtonText: "Ok",
      });
    }
  };

  const eliminarUsuario = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esto una vez realizado.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: dangerColor,
      cancelButtonColor: primaryColor,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${process.env.REACT_APP_backend}/usuarios/${id}`);
          Swal.fire("Eliminado", "El usuario ha sido eliminado correctamente", "success");
          consultarAPI(); // Recargar la lista después de la eliminación
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error al eliminar el usuario.",
            confirmButtonText: "Ok",
          });
        }
      }
    });
  };

  // Definir el dataSource de la tabla
  const dataSource = [
    {
      key: "nombre",
      campo: "Nombre",
      valor: selectedUser?.nombre,
    },
    {
      key: "email",
      campo: "Email",
      valor: selectedUser?.email,
    },
    {
      key: "telefono",
      campo: "Teléfono",
      valor: selectedUser?.telefono || "No disponible",
    },
  ];

  // Definir las columnas de la tabla
  const columns = [
    {
      title: "Campo",
      dataIndex: "campo",
      key: "campo",
      width: 200,
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      render: (text, record) =>
        editField === record.key ? (
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <Input
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              defaultValue={selectedUser[record.key]}
              style={{ width: "90%" }}
            />
            <Button
              type="primary"
              onClick={() => actualizarUsuario(record.key, editValue)}
              style={{
                marginLeft: "10px",
                backgroundColor: primaryColor,
                borderColor: primaryColor,
              }}
            >
              Guardar
            </Button>
            <Button
              type="primary"
              onClick={() => setEditField(null)}
              style={{
                marginLeft: "10px",
                backgroundColor: dangerColor,
                borderColor: dangerColor,
              }}
            >
              Cancelar
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{text}</span>
            {["admin", "super"].includes(userRole) || userId === selectedUser._id ? (
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => {
                  setEditField(record.key);
                  setEditValue(selectedUser[record.key]);
                }}
                style={{
                  marginLeft: "10px",
                  backgroundColor: primaryColor,
                  borderColor: primaryColor,
                }}
              >
                Editar
              </Button>
            ) : null}
          </div>
        ),
    },
  ];

  return (
    <Row gutter={16} style={{ margin: 0, width: "100%" }}>
      <Col
        xs={24}
        md={6}
        style={{
          backgroundColor: "#f5f5f5",
          padding: "20px",
        }}
      >
        {!userId && (
          <>
            <h1>Gestión de {rol}</h1>
            <Input.Search
              placeholder="Buscar por nombre"
              onChange={(e) => setSearchText(e.target.value)}
              style={{ marginBottom: 16, width: "100%", fontSize: "0.875em" }}
            />
            {["admin", "super"].includes(userRole) && (
              <Button
                type="primary"
                onClick={() => setModalVisible(true)}
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
              >
                Crear Nuevo {rol}
              </Button>
            )}
            <List
              pagination={{
                position: "bottom",
                total: usuarios.length,
                pageSize: pageSize,
                hideOnSinglePage: true,
              }}
              dataSource={usuarios.filter((item) =>
                item.nombre.toLowerCase().includes(searchText.toLowerCase())
              )}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button
                      type="primary"
                      icon={<EyeOutlined />}
                      onClick={() => setSelectedUser(item)}
                    >
                      Ver
                    </Button>,
                  ]}
                >
                  <List.Item.Meta title={item.nombre} description={`Rol: ${item.rol}`} />
                </List.Item>
              )}
            />
          </>
        )}
      </Col>

      <Col
        xs={24}
        md={18}
        style={{
          backgroundColor: "#ffffff",
          padding: "20px",
        }}
      >
        {selectedUser ? (
          <>
            <Title level={3} style={{ textAlign: "center" }}>
              Información del Usuario
            </Title>

            <Table dataSource={dataSource} columns={columns} pagination={false} showHeader={false} />

            <Row gutter={16} justify="center">
              <Col xs={12} style={{ textAlign: "center" }}>
                Contraseña:
                <Button
                  type="primary"
                  icon={<KeyOutlined />}
                  onClick={() => setPasswordModalVisible(true)}
                  style={{
                    marginTop: 16,
                    marginLeft: "10px",
                    width: "50%",
                    backgroundColor: primaryColor,
                    borderColor: primaryColor,
                  }}
                >
                  Cambiar
                </Button>
              </Col>
              {["admin", "super"].includes(userRole) && (
                <Col xs={12} style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => eliminarUsuario(selectedUser._id)}
                    style={{
                      marginTop: 16,
                      width: "50%",
                      backgroundColor: dangerColor,
                      borderColor: dangerColor,
                    }}
                  >
                    Eliminar Usuario
                  </Button>
                </Col>
              )}
            </Row>
          </>
        ) : (
          <Title level={4}>Seleccione un usuario para ver los detalles</Title>
        )}
      </Col>

      {/* Modal para cambiar la contraseña */}
      <Modal
        visible={passwordModalVisible}
        title="Cambiar Contraseña"
        onCancel={hidePasswordModal}
        footer={null}
      >
        <Form
          form={form}
          onFinish={cambiarPassword}
          initialValues={{ oldPassword: '', password: '', confirmPassword: '' }}
        >
          {userId === selectedUser?._id && ( // Mostrar la contraseña antigua solo para "Mi Perfil"
            <Form.Item
              label="Contraseña Antigua"
              name="oldPassword"
              rules={[{ required: true, message: "Por favor ingrese la contraseña antigua" }]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item
            label="Nueva Contraseña"
            name="password"
            rules={[{ required: true, message: "Por favor ingrese la nueva contraseña" }]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirmar Contraseña"
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              { required: true, message: "Por favor confirme la nueva contraseña" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Las contraseñas no coinciden'));
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          
          <Button type="primary" htmlType="submit" style={{ width: 150 }}>
            Cambiar Contraseña
          </Button>
        </Form>
      </Modal>

      {/* Modal para crear un nuevo usuario */}
      <Modal
        visible={modalVisible}
        title={`Crear Nuevo ${rol}`}
        onCancel={hideNewUserModal}
        footer={null}
      >
        <Form form={form} onFinish={crearNuevoUsuario}>
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[{ required: true, message: "Por favor ingrese el nombre" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Por favor ingrese el correo electrónico" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: "Por favor ingrese la contraseña" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Teléfono"
            name="telefono"
            rules={[{ required: true, message: "Por favor ingrese el teléfono" }]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: 150 }}>
            Crear
          </Button>
        </Form>
      </Modal>
    </Row>
  );
};

export default Admin;
