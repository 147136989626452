import React from "react";
import { useNavigate } from 'react-router-dom'; // Importar el hook useNavigate

const Home = () => {

const navigate = useNavigate(); // Inicializar el hook useNavigate

const handleLinkClick = (e) => {
  e.preventDefault(); // Prevenir el comportamiento predeterminado de la etiqueta <a>
  navigate('/login'); // Redirigir a la página de login
};



  return (
    <div className="main-container yoga-template">
      {/* Banner Section */}
      <div className="banner">
        <div className="banner-overlay"></div>
        <div className="inside-container top-bar">
          <div className="row">
            <div className="col-md-4 top-bar-left">
              <i className="fas fa-envelope-open-text"></i>
              <a href="mailto:ap.nutricion@gmail.com">ap.nutricion@gmail.com</a>
            </div>
            <div className="col-md-4 logo">
              <img className="logo-img" src="/img/logo1.png" alt="Logo" />
            </div>
            <div className="col-md-4 top-bar-right">
              <i className="fas fa-phone-alt"></i>
              <a href="#">7943 4761</a>
            </div>
          </div>
        </div>
        <div className="inside-container banner-content">
          <div className="row">
            <div className="col-12 content-col">
              <h1 className="site-title">Alimenta tu cuerpo, nutre tu alma.</h1>
              <p className="site-title-desc">
                Asesoría nutricional personalizada para alcanzar tus metas.
              </p>
              <a onClick={handleLinkClick} className="banner-btn">
                
                 Haz Click aquí para acceder

              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Service Section */}
      <div className="inside-container service-section">
        <div className="row sections-detail">
          <div className="col-12">
            <h2 className="section-title">Tu nutrición es nuestra prioridad</h2>
            <p className="section-title-desc">
              La nutrición es la base de una vida saludable; alimentarse bien es
              esencial para prevenir enfermedades y mantener el bienestar.
            </p>
          </div>
        </div>

        <div className="row service-detail">
          <div className="col-lg-4">
            <i
              className="fas fa-utensils service-detail-img"
              style={{ color: "#b3cb2a", fontSize: "4em" }}
            ></i>
            <a href="#">
              <h3 className="service-title">Dieta Personalizada</h3>
            </a>
            <p className="service-desc">
              Creación de planes alimentarios individuales basados en
              necesidades específicas y objetivos personales, ofreciendo
              asesoría nutricional personalizada para mejorar la salud, aumentar
              la energía y promover un bienestar general.
            </p>
          </div>

          <div className="col-lg-4">
            <i
              className="fas fa-flask service-detail-img"
              style={{ color: "#b3cb2a", fontSize: "4em" }}
            ></i>
            <a href="#">
              <h3 className="service-title">Msc. Tecnología en Alimentos</h3>
            </a>
            <p className="service-desc">
              Evaluación y mejora de la calidad y seguridad de los alimentos
              desde su producción hasta su consumo, ofreciendo asesoría en la
              selección y consumo de alimentos procesados, garantizando su valor
              nutricional y seguridad.
            </p>
          </div>

          <div className="col-lg-4">
            <i
              className="fas fa-microscope service-detail-img"
              style={{ color: "#b3cb2a", fontSize: "4em" }}
            ></i>
            <a href="#">
              <h3 className="service-title">Msc. Microbiota Intestinal</h3>
            </a>
            <p className="service-desc">
              Estudio de los microorganismos en el intestino y su impacto en la
              salud, proporcionando planes nutricionales personalizados para
              mejorar la salud intestinal y general.
            </p>
          </div>

          <div className="col-lg-4">
            <i
              className="fas fa-syringe service-detail-img"
              style={{ color: "#b3cb2a", fontSize: "4em" }}
            ></i>
            <a href="#">
              <h3 className="service-title">Esp. En Diabetes</h3>
            </a>
            <p className="service-desc">
              Especialización en el manejo nutricional de la diabetes, con
              dietas y programas de alimentación diseñados para controlar y
              mejorar los niveles de glucosa en sangre.
            </p>
          </div>

          <div className="col-lg-4">
            <i
              className="fas fa-dumbbell service-detail-img"
              style={{ color: "#b3cb2a", fontSize: "4em" }}
            ></i>
            <a href="#">
              <h3 className="service-title">
                Esp. En Suplementación Deportiva
              </h3>
            </a>
            <p className="service-desc">
              Uso de suplementos para mejorar el rendimiento y la recuperación
              deportiva, ofreciendo asesoría en la elección y uso de suplementos
              nutricionales para deportistas.
            </p>
          </div>

          <div className="col-lg-4">
            <i
              className="fas fa-baby service-detail-img"
              style={{ color: "#b3cb2a", fontSize: "4em" }}
            ></i>
            <a href="#">
              <h3 className="service-title">
                Esp. Alimentación Complementaria (BLW)
              </h3>
            </a>
            <p className="service-desc">
              Introducción de alimentos sólidos a bebés de manera segura y
              saludable (Baby-Led Weaning), brindando guías y planes para la
              alimentación complementaria de bebés, promoviendo la autonomía y
              una nutrición adecuada.
            </p>
          </div>
        </div>
      </div>

      {/* Advantages Section */}
      <div className="advantages-section">
        <div className="row sections-detail">
          <div className="col-12">
            <h2 className="section-title">Ventajas de una Buena Nutrición</h2>
            <p className="section-title-desc">
              Una buena nutrición ofrece múltiples beneficios para tu salud
              física y mental.
            </p>
          </div>
        </div>

        <div className="row advantages-detail">
          <div className="col-lg-5">
            <div className="row">
              <div className="col-12">
                <div className="advantages-detail-soul">
                  <div className="advantages-detail-img">
                    <i
                      className="fas fa-heart"
                      style={{ color: "#d94", fontSize: "4em" }}
                    ></i>
                  </div>
                  <a href="#">
                    <h3 className="advantages-detail-title">Corazón y Alma</h3>
                  </a>
                  <p className="advantages-detail-desc">
                    Una nutrición adecuada no solo fortalece el cuerpo, sino
                    también el alma, proporcionando bienestar integral.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="advantages-detail-health">
                  <div className="advantages-detail-img">
                    <i
                      className="fas fa-heartbeat"
                      style={{ color: "#d94", fontSize: "4em" }}
                    ></i>
                  </div>
                  <a href="#">
                    <h3 className="advantages-detail-title">Salud Óptima</h3>
                  </a>
                  <p className="advantages-detail-desc">
                    Mantener una dieta equilibrada ayuda a prevenir enfermedades
                    y a mantener un estado de salud óptimo.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="advantages-detail-relax">
                  <div className="advantages-detail-img">
                    <i
                      className="fas fa-spa"
                      style={{ color: "#d94", fontSize: "4em" }}
                    ></i>
                  </div>
                  <a href="#">
                    <h3 className="advantages-detail-title">
                      Relajación y Bienestar
                    </h3>
                  </a>
                  <p className="advantages-detail-desc">
                    Los alimentos ricos en nutrientes esenciales ayudan a
                    mantener la calma y el bienestar emocional.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2"></div>

          <div className="col-lg-5">
            <div className="row">
              <div className="col-12">
                <div className="advantages-detail-energy">
                  <div className="advantages-detail-img">
                    <i
                      className="fas fa-bolt"
                      style={{ color: "#d94", fontSize: "4em" }}
                    ></i>
                  </div>
                  <a href="#">
                    <h3 className="advantages-detail-title">Energía Real</h3>
                  </a>
                  <p className="advantages-detail-desc">
                    Una nutrición adecuada es la principal fuente de energía
                    para mantener un estilo de vida activo.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="advantages-detail-mind">
                  <div className="advantages-detail-img">
                    <i
                      className="fas fa-brain"
                      style={{ color: "#d94", fontSize: "4em" }}
                    ></i>
                  </div>
                  <a href="#">
                    <h3 className="advantages-detail-title">
                      Renovación Mental
                    </h3>
                  </a>
                  <p className="advantages-detail-desc">
                    Alimentar tu mente con los nutrientes adecuados mejora la
                    concentración y la claridad mental.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="advantages-detail-meditat">
                  <div className="advantages-detail-img">
                    <i
                      className="fas fa-praying-hands"
                      style={{ color: "#d94", fontSize: "4em" }}
                    ></i>
                  </div>
                  <a href="#">
                    <h3 className="advantages-detail-title">
                      Meditación y Nutrición
                    </h3>
                  </a>
                  <p className="advantages-detail-desc">
                    Una alimentación consciente potencia los beneficios de la
                    meditación, llevando a un estado de paz interior.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Nutrition Section */}
      <div className="yogaPlace-section">
        <div className="row sections-detail">
          <div className="col-12">
            <h2 className="section-title">
              Nuestra Propuesta de Alimentación Balanceada
            </h2>
            <p className="section-title-desc">
              El equilibrio en la alimentación es fundamental para un estilo de
              vida saludable
            </p>
          </div>
        </div>

        <div className="inside-container">
          <div className="row yogaPlace-col">
            <div className="col-lg-3">
              <div className="yogaPlace-img">
                <img
                  src="/img/frutas-y-verduras.jpg"
                  className="yogaPlace-in-img"
                  alt="Frutas y Verduras"
                />
              </div>
              <a href="#">
                <h3 className="yogaPlace-title">Frutas y Verduras</h3>
              </a>
              <p className="yogaPlace-desc">
                Los colores en tu plato aseguran una nutrición variada y
                completa, llena de vitaminas y minerales esenciales.
              </p>
            </div>

            <div className="col-lg-3">
              <div className="yogaPlace-img">
                <img
                  src="/img/proteinas.jpg"
                  className="yogaPlace-in-img"
                  alt="Proteínas"
                />
              </div>
              <a href="#">
                <h3 className="yogaPlace-title">Proteínas de Calidad</h3>
              </a>
              <p className="yogaPlace-desc">
                Las proteínas son los bloques de construcción de tus músculos y
                tejidos, fundamentales para el desarrollo y reparación del
                cuerpo.
              </p>
            </div>

            <div className="col-lg-3">
              <div className="yogaPlace-img">
                <img
                  src="/img/grasas-saludables.jpg"
                  className="yogaPlace-in-img"
                  alt="Grasas Saludables"
                />
              </div>
              <a href="#">
                <h3 className="yogaPlace-title">Grasas Saludables</h3>
              </a>
              <p className="yogaPlace-desc">
                Las grasas saludables son esenciales para la absorción de
                vitaminas y el funcionamiento óptimo de tu sistema nervioso.
              </p>
            </div>

            <div className="col-lg-3">
              <div className="yogaPlace-img">
                <img
                  src="/img/hidratos-de-carbono.jpg"
                  className="yogaPlace-in-img"
                  alt="Hidratos de Carbono"
                />
              </div>
              <a href="#">
                <h3 className="yogaPlace-title">
                  Hidratos de Carbono Complejos
                </h3>
              </a>
              <p className="yogaPlace-desc">
                Los carbohidratos complejos proporcionan energía sostenida y son
                la base para una dieta equilibrada.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div className="inside-container contact-section">
        <div className="row sections-detail">
          <div className="col-12">
            <h2 className="section-title">Formulario de citas y horarios</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="appointment">
              <h2 className="appointment-title">Haz una cita</h2>
              <p className="appointment-desc">
                Introduce tus datos y nuestra secretaria se pondrá en contacto a
                la brevedad posible para confirmar tu cita.
              </p>
              <form action="#" className="appointment-form">
                <div className="row">
                  <div className="col-lg-6 appointment-form-col-left">
                    <input
                      type="text"
                      className="form-control appointment-form-input"
                      placeholder="First name*"
                      required
                    />
                  </div>
                  <div className="col-lg-6 appointment-form-col-right">
                    <input
                      type="text"
                      className="form-control appointment-form-input"
                      placeholder="Last name*"
                      required
                    />
                  </div>
                  <div className="col-lg-6 appointment-form-col-left">
                    <input
                      type="date"
                      className="form-control appointment-form-input data-input"
                      required
                    />
                  </div>
                  <div className="col-lg-6 box-select appointment-form-col-right">
                    <select
                      name="gender"
                      className="form-control appointment-form-input"
                    >
                      <option value="choose-class">Choose Class</option>
                      <option value="class-1">Class 1</option>
                      <option value="class-2">Class 2</option>
                      <option value="class-3">Class 3</option>
                    </select>
                  </div>
                  <div className="col-lg-6 appointment-form-col-left">
                    <input
                      type="tel"
                      className="form-control appointment-form-input"
                      placeholder="Phone"
                      required
                      maxLength="10"
                    />
                  </div>
                  <div className="col-lg-6 appointment-form-col-right">
                    <input
                      type="email"
                      className="form-control appointment-form-input"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="col-12 appointment-form-col-left">
                    <textarea
                      className="form-control appointment-form-text"
                      rows="3"
                      placeholder="Message (Optional)"
                    ></textarea>
                  </div>
                  <div className="col-12 appointment-form-col-right">
                    <button type="submit" className="appointment-form-btn">
                      Enviar cita
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-6 open-time">
            <div className="weekdays">
              <h3 className="open-time-title">Lunes a viernes</h3>
              <p className="open-time-clock">09.00 AM - 12.00 PM</p>
              <p className="open-time-course">
                12.00 PM - 01.00 PM Cerrado por almuerzo
              </p>
              <p className="open-time-clock">01.00 PM - 05.00 PM</p>
            </div>
            <div className="weekends">
              <h3 className="open-time-title">Sábados</h3>
              <p className="open-time-clock">09.00 AM - 12.00 PM</p>
              <p className="open-time-course">Cerrado después de almuerzo</p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="footer">
        <div className="copyright">
          <div className="inside-container">
            <div className="row">
              <div className="col-md-6 order-2 order-md-12">
                <p>
                  &copy; 2024 Todos Los derechos reservados, citio web
                  desarrollado por{" "}
                  <a href="https://oscar27jimenez.com/" className="created-by">
                    Ing. Oscar Jiménez
                  </a>
                </p>
              </div>
              <div className="col-md-6 social order-1 order-md-12">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="bi bi-tiktok"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;