import React, { useState, useEffect } from 'react';
import { Layout, Button, Space, Typography, Dropdown, Menu } from 'antd';
import {
  UserOutlined,
  DownOutlined,
  LogoutOutlined,
  CalendarOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import logo from '../../img/logo.png';
import './Dashboard.css';
import Admin from '../admin/Admin';
import MailConfig from '../mailconfig/MailConfig';

const Citas = () => <h2>Citas Component</h2>;

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

const Dashboard = () => {
  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [selectedComponent, setSelectedComponent] = useState('Citas');
  const [dropdownLabelUsuarios, setDropdownLabelUsuarios] = useState('Usuarios');
  const [dropdownLabelConfig, setDropdownLabelConfig] = useState('Config.');
  const [isMobile, setIsMobile] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserName(decoded.nombre);
        setUserRole(decoded.rol);
        setUserId(decoded.id);
      } catch (error) {
        console.error('Error al decodificar el token', error);
      }
    } else {
      setUserName('Usuario');
    }
  }, []);

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Citas':
        return <Citas />;
      case 'Pacientes':
        return <Admin rol="paciente" userRole={userRole} />;
      case 'Asistentes':
        return <Admin rol="asistente" userRole={userRole} />;
      case 'Admin':
        return <Admin rol="admin" userRole={userRole} />;
      case 'ConfigEmail':
        return <MailConfig />;
      case 'MiPerfil':
        return <Admin userId={userId} userRole={userRole} />;
      default:
        return <Citas />;
    }
  };

  const handleLogout = () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Quieres cerrar sesión?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#faabb2',
      cancelButtonColor: '#abd4ac',
      confirmButtonText: 'Sí, cerrar sesión',
      cancelButtonText: 'Cancelar',
      customClass: {
        title: 'swal-title',
        text: 'swal-text',
        confirmButton: 'swal-confirm-button',
        cancelButton: 'swal-cancel-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('token');
        Swal.fire('Cerrado!', 'Tu sesión ha sido cerrada.', 'success');
        navigate('/');
      }
    });
  };

  const handleMenuClick = (key, label) => {
    setSelectedComponent(key);
    setVisible(false);
  };

  const handleUserMenuClick = (key) => {
    setDropdownLabelUsuarios(key); // Set the label of Usuarios dropdown
    setSelectedComponent(key); // Update the selected component
  };

  const handleConfigMenuClick = (key) => {
    setDropdownLabelConfig(key); // Set the label of Config dropdown
  setSelectedComponent('ConfigEmail'); // Fija el componente a ConfigEmail independientemente de la clave
  };

  const usuariosMenu = (
    <Menu>
      <Menu.Item key="Pacientes" onClick={() => handleUserMenuClick('Pacientes')}>
        Pacientes
      </Menu.Item>
      {['admin', 'super'].includes(userRole) && (
        <>
          <Menu.Item key="Asistentes" onClick={() => handleUserMenuClick('Asistentes')}>
            Asistentes
          </Menu.Item>
          <Menu.Item key="Admin" onClick={() => handleUserMenuClick('Admin')}>
            Admin
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const configMenu = (
    <Menu>
      {['admin', 'super'].includes(userRole) && (
        <Menu.Item key="ConfigEmail" onClick={() => handleConfigMenuClick('Email')}>
          Config 1
        </Menu.Item>
      )}
         {['admin', 'super'].includes(userRole) && (
        <Menu.Item key="ConfigEmail" onClick={() => handleConfigMenuClick('Email')}>
          Config 2
        </Menu.Item>
      )}
         {['super'].includes(userRole) && (
        <Menu.Item key="ConfigEmail" onClick={() => handleConfigMenuClick('Email')}>
          Configuar mail
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Layout className="layout">
      <Header className="navbar">
        <div className="navbar-left">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </div>

        <div className="navbar-right">
          {isMobile ? (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="Citas" onClick={() => handleMenuClick('Citas', 'Citas')}>
                    Citas
                  </Menu.Item>
                  <Dropdown overlay={usuariosMenu}>
                    <Menu.Item key="Usuarios">
                      Usuarios <DownOutlined />
                    </Menu.Item>
                  </Dropdown>
                  <Dropdown overlay={configMenu}>
                    <Menu.Item key="Config">
                      Config. <DownOutlined />
                    </Menu.Item>
                  </Dropdown>
                  <Menu.Item key="MiPerfil" onClick={() => handleMenuClick('MiPerfil', 'Mi Perfil')}>
                    Mi Perfil
                  </Menu.Item>
                  <Menu.Item key="Cerrar Sesión" onClick={handleLogout}>
                    Cerrar Sesión
                  </Menu.Item>
                </Menu>
              }
              visible={visible}
              onVisibleChange={(flag) => setVisible(flag)}
              placement="bottomLeft"
            >
              <Button className="ant-dropdown-trigger" style={{ fontSize: '18px' }}>
                {selectedComponent} <DownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <Space className="navbar-buttons">
              <Button
                type="default"
                icon={<CalendarOutlined />}
                className={`navbar-button ${selectedComponent === 'Citas' ? 'active-button' : ''}`}
                onClick={() => setSelectedComponent('Citas')}
                style={{ fontSize: '18px' }}
              >
                Citas
              </Button>
              <Dropdown overlay={usuariosMenu}>
                <Button type="default" icon={<TeamOutlined />} className="navbar-button" style={{ fontSize: '18px' }}>
                  {dropdownLabelUsuarios} <DownOutlined />
                </Button>
              </Dropdown>
              {['admin', 'super'].includes(userRole) && (
                <Dropdown overlay={configMenu}>
                  <Button type="default" icon={<ToolOutlined />} className="navbar-button" style={{ fontSize: '18px' }}>
                    {dropdownLabelConfig} <DownOutlined />
                  </Button>
                </Dropdown>
              )}
              <Button
                type="default"
                icon={<UserOutlined />}
                className={`navbar-button ${selectedComponent === 'MiPerfil' ? 'active-button' : ''}`}
                onClick={() => setSelectedComponent('MiPerfil')}
                style={{ fontSize: '18px' }}
              >
                Mi Perfil
              </Button>
              <Button type="default" icon={<LogoutOutlined />} className="danger" onClick={handleLogout} style={{ fontSize: '18px' }}>
                Cerrar Sesión
              </Button>
            </Space>
          )}
        </div>
      </Header>

      <div className="user-info">
        <Text className="navbar-user-info" style={{ fontSize: '18px' }}>
          <UserOutlined style={{ marginRight: 8 }} /> {userName} ({userRole})
        </Text>
      </div>

      <Content style={{ padding: '50px', minHeight: '80vh' }}>{renderComponent()}</Content>

      <Footer style={{ textAlign: 'center', fontSize: '16px' }}>
        Development & Design by Eng. Oscar Jiménez ©2024
      </Footer>
    </Layout>
  );
};

export default Dashboard;
