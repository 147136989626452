import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col, Typography, Form, Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";

const { Title } = Typography;

const MailConfig = () => {
  const [form] = Form.useForm();
  const [config, setConfig] = useState({});
  const [editField, setEditField] = useState(null);
  const [editValue, setEditValue] = useState("");
  const primaryColor = "#007BFF";
  const dangerColor = "#FF4C4C";

  // Cargar la configuración existente desde la API
  const cargarConfiguracion = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_backend}/mail-config`);
      setConfig(response.data);
      form.setFieldsValue(response.data); // Rellena el formulario con los datos cargados
    } catch (error) {
      console.error("Error al cargar la configuración", error);
    }
  };

  useEffect(() => {
    cargarConfiguracion();
  }, []);

  // Actualizar la configuración
  const actualizarConfig = async (field, value) => {
    if (value === "") return;

    try {
      await axios.put(`${process.env.REACT_APP_backend}/mail-config`, {
        ...config,
        [field]: value,
      });
      Swal.fire({
        icon: "success",
        title: "Actualización exitosa",
        text: `${field} ha sido actualizado correctamente`,
        confirmButtonText: "Ok",
      });
      cargarConfiguracion(); // Recargar los datos actualizados
      setEditField(null); // Salir del modo de edición
    } catch (error) {
      console.error("Error al actualizar la configuración", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error al actualizar la configuración.",
        confirmButtonText: "Ok",
      });
    }
  };

  const columns = [
    {
      title: "Campo",
      dataIndex: "campo",
      key: "campo",
      width: 200,
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      render: (text, record) =>
        editField === record.key ? (
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <Input
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              defaultValue={config[record.key]}
              style={{ width: "90%" }}
            />
            <Button
              type="primary"
              onClick={() => actualizarConfig(record.key, editValue)}
              style={{ marginLeft: "10px", backgroundColor: primaryColor, borderColor: primaryColor }}
            >
              Guardar
            </Button>
            <Button
              type="primary"
              onClick={() => setEditField(null)}
              style={{ marginLeft: "10px", backgroundColor: dangerColor, borderColor: dangerColor }}
            >
              Cancelar
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{text}</span>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                setEditField(record.key);
                setEditValue(config[record.key]);
              }}
              style={{ marginLeft: "10px", backgroundColor: primaryColor, borderColor: primaryColor }}
            >
              Editar
            </Button>
          </div>
        ),
    },
  ];

  const dataSource = [
    {
      key: "emailSender",
      campo: "Correo del Remitente",
      valor: config.emailSender,
      ejemplo: "Ejemplo: contacto@empresa.com",
    },
    {
      key: "emailPassword",
      campo: "Contraseña",
      valor: "*******", // No mostrar la contraseña real
      ejemplo: "Ejemplo: Contraseña segura",
    },
    {
      key: "smtpHost",
      campo: "Servidor SMTP",
      valor: config.smtpHost,
      ejemplo: "Ejemplo: smtp.gmail.com",
    },
    {
      key: "smtpPort",
      campo: "Puerto SMTP",
      valor: config.smtpPort,
      ejemplo: "Ejemplo: 587 (para TLS) o 465 (para SSL)",
    },
    {
      key: "telefono",
      campo: "Teléfono de Contacto",
      valor: config.telefono,
      ejemplo: "Ejemplo: +52 555 555 5555",
    },
  ];

  return (
<div style={{ display: 'flex', justifyContent: 'center' }}> {/* Centrado solo horizontal */}
      <div style={{ width: 700, padding: '20px', backgroundColor: '#fff', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}> {/* Cuadro centrado */}
        <Title level={3} style={{ textAlign: "center" }}>
          Configuración de Correo
        </Title>

        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          showHeader={false}
          bordered
        />
      </div>
    </div>
  );
};

export default MailConfig;
