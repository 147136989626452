import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import './Login.css';
import logo from '../../img/logo.png';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false); // Estado para mostrar el modal de recuperación
  const [recoverEmail, setRecoverEmail] = useState(""); // Estado para manejar el correo ingresado para recuperar
  const navigate = useNavigate();

  // Función para iniciar sesión
  const onFinish = async (values) => {
    const { email, password } = values;
    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_backend}/iniciar`, { email, password });
      const { token } = response.data;

      localStorage.setItem("token", token);

      Swal.fire({
        icon: "success",
        title: "Inicio de sesión exitoso",
        text: "Bienvenido de nuevo!",
        confirmButtonText: "Ok",
      });
      navigate('/dashboard');
      
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Credenciales incorrectas. Por favor, intenta de nuevo.",
        confirmButtonText: "Ok",
      });
    } finally {
      setLoading(false);
    }
  };

  // Función para manejar la recuperación de contraseña
const handleRecoverPassword = async () => {
  try {
    console.log("Correo ingresado para recuperación:", recoverEmail); // Log para verificar el correo
    const response = await axios.post(`${process.env.REACT_APP_backend}/recuperar-pass`, { email: recoverEmail });
    Swal.fire({
      icon: "success",
      title: "Correo enviado",
      text: "Revisa tu correo para continuar con la recuperación de contraseña.",
      confirmButtonText: "Ok",
    });
    setShowRecoverModal(false); // Cierra el modal después de enviar el correo
  } catch (error) {
    console.error("Error al enviar la solicitud de recuperación de contraseña:", error); // Ver error en consola
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "No se encontró una cuenta asociada con ese correo.",
      confirmButtonText: "Ok",
    });
  }
};


  const handleCancel = () => {
    navigate("/"); 
  };

  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Col xs={24} sm={12} md={8}>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img src={logo} alt="Logo" style={{ width: "400px", height: "auto" }} />
        </div>
        
        <h2 style={{ textAlign: "center", marginBottom: "30px" }}>Iniciar Sesión</h2>

        <Form
          name="login"
          layout="vertical"
          onFinish={onFinish}
          style={{ maxWidth: "400px", margin: "0 auto" }}
        >
          <Form.Item
            label="Correo electrónico"
            name="email"
            rules={[{ required: true, message: "Por favor ingrese su correo" }]}
          >
            <Input placeholder="Ingrese su correo" />
          </Form.Item>

          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: "Por favor ingrese su contraseña" }]}
          >
            <Input.Password
              placeholder="Ingrese su contraseña"
              iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "100%", marginBottom: "10px" }}
            >
              {loading ? "Iniciando sesión..." : "Iniciar Sesión"}
            </Button>
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "100%" }}
            >
              Cancelar
            </Button>
          </Form.Item>

          {/* Botón para recuperar la contraseña */}
          <Form.Item>
            <Button type="link" onClick={() => setShowRecoverModal(true)} style={{ width: "100%" }}>
              ¿Olvidaste tu contraseña?
            </Button>
          </Form.Item>
        </Form>
      </Col>

      {/* Modal de recuperación de contraseña */}
      <Modal
        visible={showRecoverModal}
        title="Recuperar Contraseña"
        onCancel={() => setShowRecoverModal(false)}
        footer={null}
      >
        <Form onFinish={handleRecoverPassword}>
          <Form.Item
            label="Correo electrónico"
            name="email"
            rules={[{ required: true, message: "Por favor ingrese su correo" }]}
          >
            <Input 
              placeholder="Ingrese su correo" 
              value={recoverEmail} 
              onChange={(e) => setRecoverEmail(e.target.value)} 
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Enviar link de recuperación
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default Login;
